/*jshint esversion: 6 */
"use strict";

var Vue = require('vue/dist/vue');
import VueResource from 'vue-resource';

Vue.use(VueResource);

import moment from 'moment-timezone';

import jQuery from "jquery";
const $ = (window.$ = window.jQuery = jQuery);

import ivbus from "@/utils/ivbus.js";
import apiClient from "@/utils/apiClient.js";

const TwilioChat = require("twilio-chat"); // get from npm, but Uglify isn't happy with ES6
// const TwilioChat = require("twilio-chat"); // get from main.marko script declaration

export var commonTwilioMixin = {
	data() {
		return {
			// implicitly "self" whether self is org or ivee
			unreadMessages: 0,
			identity: '',
			friendlyName: '',

			sendText: '',

			// these are originally loaded from twilioState but get replaced by Subscribed channels. Or do Subscribed channels live in their own list/map?
			channelsList: [],
			channelsMap: {},

			// channel that is currently open and being displayed, ie is marked as read immediately when messages come in
			// "renderable" in the sense that it is reactive, exposed to Vue
			renderableChannel: null,
			bedeliaChatOpen: false,
			bedeliaSpinId: null,
			messageBeingSent: false,

			twilioTokenUrl: "/api/twilio/token/",
			twilioStateUrl: "/api/twilio/state/"
		}
	},
	created: function () {
		this.ivTwilio = {};
	},
	mounted: function () {
		var that = this;

		// kick off our initializatoin requests (both of these will set ivbus flags that will be checked where they're needed downstream)
		that.initializeTwilio();
		that.getTwilioState();


		// initialize non-reactive vars
		that.ivTwilio.subscribedChannels = {};

		that.ivTwilio.messageList = document.getElementById("message-list");
		that.ivTwilio.lastConsumedMessageIndexBuffer = {};
		that.ivTwilio.messageConsumption = {};
		that.ivTwilio.messageConsumptionQueued = false;
		that.ivTwilio.onModalVisible = function () {};

		ivbus.$on('setUnreadMessageCount', function (unreadInfo) {
			Vue.set(that.channelsMap[unreadInfo.channel_sid], "unreadMessages", unreadInfo.count);
			that.setUnreadMessagesCount();
		});

		// initialization follow TwilioClient and TwilioCache (org/ivee specific?)

		// NOTE: for these three cases, last time I looked it it it was subscribing to every single channel for the client. Is it any better now? Can something be optimized?
		// haha, maybe we could have a system notifications twilio account and when the webhook is triggered we reforward the message to the ssytem account!
		Promise.all([ivbus.getFlag('twilioClient'), ivbus.getFlag('twilioState')]).then(function () {

			//listen for the creation of new channels and add those to our local array
			if (that.ivTwilio.client === undefined) {
				console.log("client not yet ready")
			} else {
				that.ivTwilio.client.on('channelAdded', function (channel) {
					//console.log("channelAdded");

					// Okay. onChannelAdded, we should just get the data from twilioState, somehow. 
					// I don't want to duplicate the data (friendly_name) in channel.attributes. Well maybe I just query for identity info.
					// Hm. 

					// Org case: we know when we're creating a channel. But we don't know the SID and such until it's been created. But we DO know the unique name and friendly name and can match those up.
					// Ivee case: the channel appears out of the blue adn we know nothing about it. But extremely unlikely to happen. And/or if it does, we can just refresh our entire TwilioState.

					// the channel doesn't exist in our twilioState cache, which means it is (hopefully) genuinely new, rather than iterating through the list on initialization
					if (that.channelsMap[channel.sid]) {
						return;
					}

					var identities = channel.uniqueName.split(":");
					var channelObj = {
						channel_sid: channel.sid,
						other_identity: identities[0] == that.identity ? identities[1] : identities[0],
						other_friendly_name: "New Chat", // c.user_identification__friendly_name || c.org_identification__friendly_name,
						last_message_sent_by: "",
						last_message_sent_at: "",
						last_message_body: "",
						unreadMessages: 0,

						messages: []
					};

					Vue.set(that.channelsMap, channel.sid, channelObj);
					that.channelsList.push(channelObj);
				});

				that.ivTwilio.client.on('messageAdded', function (message) {
					//console.log("messageAdded");

					// ignore messages that aren't on channels we know about (it could be on a feed channel, or one that was somehow created apart from an associated send)
					if (!that.channelsMap[message.channel.sid]) {
						return;
					}

					that.channelsMap[message.channel.sid].last_message_sent_by = message.author;
					that.channelsMap[message.channel.sid].last_message_sent_at = message.timestamp;
					that.channelsMap[message.channel.sid].last_message_body = message.body;

					// wait 6 seconds for the consumption report to reach twilio
					setTimeout(function () {
						message.channel.getUnconsumedMessagesCount().then(function (count) {
							if (count > 0) {
								Vue.set(that.channelsMap[message.channel.sid], "unreadMessages", Math.max(count - message.channel.attributes.autoUnconsumedCount, 0));
							} else {
								Vue.set(that.channelsMap[message.channel.sid], "unreadMessages", 0);
							}
							that.setUnreadMessagesCount();
						}).catch(function (error) {
							console.log(error);
						});
					}, 6000);

				});

				that.ivTwilio.client.on('channelUpdated', function (channel) {
					//console.log("channelUpdated");
					if (!that.channelsMap[channel.sid]) {
						return;
					}

					if (that.channelsMap[channel.sid].channelUpdatedInitialized) {
						// this is not the initialization call; pay attention to it!
						channel.getUnconsumedMessagesCount().then(function (count) {
							if (count > 0) {
								Vue.set(that.channelsMap[channel.sid], "unreadMessages", Math.max(count - channel.attributes.autoUnconsumedCount, 0));
							} else {
								Vue.set(that.channelsMap[channel.sid], "unreadMessages", 0);
							}
							that.setUnreadMessagesCount();
						}).catch(function (error) {
							console.log(error);
						});
					} else {
						// ignore the first time this event is triggered for each channel
						that.channelsMap[channel.sid].channelUpdatedInitialized = true;
					}
				});
			}

		});

	},
	methods: {
		datetimeFormat: function (date) {
			return moment(date).format("MMM D, YYYY h:mm:ss A");
		},

		initializeTwilio: function () {
			var that = this;

			// initialize twilio client
			apiClient.get(that.twilioTokenUrl).then(function (response) {
				if (response.data.status == 200) {
					that.identity = response.data.user_identity;
					that.friendlyName = response.data.user_friendly_name;
					return TwilioChat.Client.create(response.data.identity_token);
				}
			}).then(function (chatClient) {
				that.ivTwilio.client = chatClient;

				var refreshTokenTimer = function () {
					apiClient.get(that.twilioTokenUrl).then(function (response) {
						if (response.data.status == 200) {
							that.ivTwilio.client.updateToken(response.data.identity_token);
							setTimeout(refreshTokenTimer, 1000 * 60 * 55);
						}
					}).catch(function (error) {
						setTimeout(refreshTokenTimer, 1000 * 15); // if the token call failed, try again in 15 seconds
					});
				};
				setTimeout(refreshTokenTimer, 1000 * 60 * 55);

				that.ivTwilio.client.on('connectionStateChanged', function (connectionState) {
					console.log(new Date().toLocaleString() + ": Connection State changed: " + connectionState);
					// if we have an undesirable connection state, try to figure out what went wrong and fix it!
				});

				ivbus.setFlag('twilioClient', that.ivTwilio.client);

			}).catch(function (error) {
				console.log(error);
			});
		},

		getTwilioState: function () {
			var that = this;

			apiClient.get(that.twilioStateUrl).then(function (response) {
				if (response.data.status == 200) {
					response.data.channels.forEach(function (c) {
						// so now we can directly set that.channelsMap and that.channelsList, since we check for membership here before adding a channel in the channelAdded listener
						var identities = c.channel_unique_name.split(":");
						var channelObj = {
							channel_sid: c.channel_sid,
							other_identity: identities[0] == that.identity ? identities[1] : identities[0],
							other_friendly_name: c.ivee_identity__friendly_name || c.org_identity__friendly_name,
							last_message_sent_by: c.last_message__sent_by__identity,
							last_message_sent_at: c.last_message__sent_at,
							last_message_body: c.last_message__body,
							unreadMessages: c.unreadCount,
							autoReplyEnabled: c.autoreply_enabled,
							autoReplyMessage: c.autoreply_message,

							messages: []
						};

						Vue.set(that.channelsMap, c.channel_sid, channelObj);
						that.channelsList.push(channelObj);
					});
					that.setUnreadMessagesCount();

					ivbus.setFlag('twilioState', response.data.channels);
				}
			}).catch(function (error) {
				console.log(error);
			});

			// TODO: we could have another function twilio/summary that only returns "interesting" channels, however that is defined (with unread messages, from past __ days, etc)
			// NOTE: we are having this call run and complete before we attach the event listeners to the client. This opens up a few seconds where channels/messages could be created that we don't notice, but for now it's simpler than deciding whether to spend extra twilio reads getting data about channels that will soon arrive in the cache, vs newly added channels that don't exist in the cache yet
			// One possible solution is to poll the server for TwilioState periodically (or even after subscriptions have been set up) with a timestamp of checking to see if anythign new came in while we were gone

		},

		initializeAndSelectChannel: function (channel, friendly_name) {
			var that = this;

			that.ivTwilio.lastConsumedMessageIndexBuffer[channel.sid] = 0;

			// TODO: probably don't override this if it's already set
			Vue.set(that.channelsMap[channel.sid], "messages", []);

			channel.on('messageAdded', function (message) {
				// add message to scope array
				if (that.channelsMap[message.channel.sid].messages.filter(function (m) {
						return m.sid == message.sid;
					}).length === 0) {
					that.channelsMap[message.channel.sid].messages.push(message);
				}

				// if we're currently viewing the channel this message arrived on, scroll to bottom and mark as consumed
				if (that.renderableChannel && that.renderableChannel.channel_sid === message.channel.sid) { // that.modalVisible && 
					setTimeout(function () {
						$("#message-list")[0].scrollTop = $("#message-list")[0].scrollHeight;
					});
					that.resetUnreadMessageCount(that.ivTwilio.currentChannel);
				}
			});

			that.getMessages(channel).then(function (data) {
				//that.ivTwilio.lastConsumedMessageIndexBuffer[channel.sid] = channel.lastConsumedMessageIndex;

				Vue.set(that.channelsMap[channel.sid], "messages", data.messages);
				Vue.set(that.channelsMap[channel.sid], "memberFriendlyNames", data.memberFriendlyNames);

				setTimeout(function () {
					$("#message-list")[0].scrollTop = $("#message-list")[0].scrollHeight;
				});

				that.ivTwilio.subscribedChannels[channel.sid] = channel;
				that.selectChannel(channel);
			});
		},

		badgeCountForInterview: function (iv) {
			return this.channelsMap[iv.channel_sid] ? this.channelsMap[iv.channel_sid].unreadMessages : 0;
		},

		setUnreadMessagesCount: function (ch) {
			var that = this;
			console.log("setUnreadMessagesCount");

			var newCount = 0;

			// iterate through the channels to count up unread messages.
			that.channelsList.forEach(function (c) {
				newCount += that.channelsMap[c.channel_sid].unreadMessages;
			});

			that.unreadMessages = newCount;
		},

		// from twilioMixin

		getMessages: function (channel) {
			var that = this;
			// TODO: spinner?
			return new Promise(function (resolve, reject) {
				// TODO: do we need to check for inChannel and join() if not?
				channel.getMessages().then(function (messages) {

					var memberFriendlyNames = {};
					that.ivTwilio.currentPage = messages;
					resolve({
						messages: messages.items,
						memberFriendlyNames: {} /*memberFriendlyNames*/
					}); // deal with pagination stuff somehow		            	

				});
			});
		},

		getPreviousPage: function () {
			var that = this;
			return new Promise(function (resolve, reject) {
				if (that.ivTwilio.currentPage.hasPrevPage) {
					that.ivTwilio.currentPage.prevPage().then(function (newPage) {
						that.ivTwilio.currentPage = newPage;
						resolve(newPage.items);
					});
				} else {
					resolve([]);
				}
			});
		},

		send: function (event) {
			// if shift is being held down, add newline to sendText and break
			if (event && event.shiftKey) {
				this.sendText += "\n";
				return;
			}

			if (this.messageBeingSent) {
				return;
			}
			if (this.sendText) {
				this.sendMessage(this.sendText, this.ivTwilio.currentChannel);
			}
		},

		sendMessage: function (messageText, channel) {
			var that = this;
			var spinId = that.spinStart({
				spinkey: "bedelia"
			});
			that.messageBeingSent = true;
			var watch;
			var watchdog = function () {
				console.log("watchdog");
				clearTimeout(watch);
				watch = setTimeout(function () {
					that.messageBeingSent = false;
					that.spinStop(spinId);
					that.showMessage("Message failed to send. Please refresh the page to try again.", "alert-danger", this.uiMessageWaitLong);
				}, 20000);
			}
			watchdog();
			channel.sendMessage(messageText).then(function (response) {
				that.messageBeingSent = false;
				that.spinStop(spinId);
				clearTimeout(watch);
				ivbus.$emit("clearSendText");
			}).catch(function (error) {
				that.spinStop(spinId);
				that.showMessage("Message failed to send. Please refresh the page to try again.", "alert-danger", this.uiMessageWaitLong);
			});
		},

		setMessageConsumption: function (channel_sid, index) {
			var that = this;
			that.ivTwilio.messageConsumption[channel_sid] = index;
			if (!that.ivTwilio.messageConsumptionQueued) {
				that.ivTwilio.messageConsumptionQueued = true;
				setTimeout(function () {
					that.ivTwilio.messageConsumptionQueued = false;
					var consumedMessages = that.ivTwilio.messageConsumption;
					that.ivTwilio.messageConsumption = {}
					apiClient.post("/api/twilio/consumption/", consumedMessages);
				}, 4000);
			}
		},

		// from org-modal-messages-controller

		selectChannel: function (channel) {
			var that = this;

			that.spinStop(that.bedeliaSpinId);

			that.ivTwilio.currentChannel = channel;
			that.renderableChannel = that.channelsMap[channel.sid];

			setTimeout(function () {
				$("#message-list")[0].scrollTop = $("#message-list")[0].scrollHeight;
			});
			this.resetUnreadMessageCount(that.ivTwilio.currentChannel);

		},

		// called when the channel is viewed
		resetUnreadMessageCount: function () {
			var that = this;
			console.log("resetting unread message count");
			// update consumption on Twilio

			var applyResetUnreadMessageCount = function () {
				console.log("applying unread message count");
				that.ivTwilio.currentChannel.setAllMessagesConsumed();
				// update attributes
				if (that.ivTwilio.currentChannel.attributes.autoUnconsumedCount > 0) {
					var newAttributes = JSON.parse(JSON.stringify(that.ivTwilio.currentChannel.attributes));
					newAttributes.autoUnconsumedCount = 0;
					that.ivTwilio.currentChannel.updateAttributes(newAttributes);
				}

				if (that.channelsMap[that.renderableChannel.channel_sid].messages.length > 0) {
					// update our server
					that.setMessageConsumption(that.renderableChannel.channel_sid, that.channelsMap[that.renderableChannel.channel_sid].messages[that.channelsMap[that.renderableChannel.channel_sid].messages.length - 1].index);
					// update client cache (although soon it will be updated by twilio callbacks)
					console.log("emitting setUnreadMessageCount");
					ivbus.$emit('setUnreadMessageCount', {
						channel_sid: that.renderableChannel.channel_sid,
						count: 0
					});
				}
			}

			if (that.bedeliaChatOpen) {
				applyResetUnreadMessageCount();
			}
			// else, this will be called again when the chat is opened again
		},

	}
}