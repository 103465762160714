<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-title text-center">
          <div v-if="context == 'ao'">
            <div class="pull-left" v-if="renderableChannel">
              <div
                class="toggle-ios"
                v-on:click="toggleAutoReply(renderableChannel)"
                style="margin-top: -5px; float: left"
              >
                <div
                  class="toggle-icon"
                  v-bind:class="{
                    'toggle-checked': renderableChannel.autoReplyEnabled,
                  }"
                >
                  <div class="toggle-inner"></div>
                </div>
              </div>
              <div style="padding: 0px 10px; float: left">
                <small>
                  Auto Reply
                  <span v-show="renderableChannel.autoReplyEnabled"
                    >Enabled</span
                  >
                  <span v-show="!renderableChannel.autoReplyEnabled"
                    >Disabled</span
                  >
                </small>
              </div>
            </div>
          </div>

          <span v-if="renderableChannel">{{
            renderableChannel.other_friendly_name
          }}</span>
          <button
            type="button"
            class="close"
            v-on:click="closeBedeliaChat()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div v-if="context == 'ao'">
          <div v-if="renderableChannel">
            <div class="row" v-show="showAutoReplyEditor">
              <div class="col-xs-12">
                <h4>Set an Auto-Reply Message</h4>
              </div>
              <div class="col-xs-12">
                <div class="row" style="margin-bottom: 0px">
                  <div class="col-xs-6">
                    <textarea
                      class="form-control"
                      v-model="candidateAutoReplyMessage"
                    ></textarea>
                  </div>
                  <div class="col-xs-6">
                    <button
                      style="margin-top: 10px; margin-right: 10px"
                      class="btn btn-success"
                      type="button"
                      v-on:click="saveAutoReply(renderableChannel)"
                    >
                      Enable
                    </button>
                    <button
                      style="margin-top: 10px"
                      class="btn btn-default"
                      type="button"
                      v-on:click="cancelAutoReplyEditor()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-body">
        <div>
          <div class="main-app">
            <div>
              <div class="messenger">
                <div class="right-panel">
                  <div>
                    <div>
                      <div id="message-list" class="message-list">
                        <div v-if="renderableChannel">
                          <div
                            v-for="message in renderableChannel.messages"
                            v-bind:key="message.timestamp"
                            track-by="sid"
                          >
                            <div
                              class="bubble-position"
                              v-bind:class="{
                                'bubble-position--alt':
                                  message.author == identity,
                              }"
                            >
                              <div class="name">
                                {{
                                  message.author == identity
                                    ? friendlyName
                                    : renderableChannel.other_friendly_name
                                }}
                                - {{ datetimeFormat(message.timestamp) }}
                              </div>
                              <div
                                class="bubble"
                                v-bind:class="{
                                  'bubble--alt': message.author == identity,
                                }"
                              >
                                {{ message.body }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="typing-indicator-panel"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <div class="message-composer">
          <textarea
            v-on:keydown.enter.prevent.stop="send($event)"
            v-model="sendText"
            class="message-textarea"
            v-bind:disabled="messageBeingSent || !renderableChannel"
            v-bind:class="{ disabled: messageBeingSent || !renderableChannel }"
            placeholder="Enter a message..."
            rows="3"
          ></textarea>
          <button
            class="btn btn-default"
            style="margin: 5px"
            v-on:click="send()"
          >
            Send
          </button>
          <button
            class="btn btn-default btn-sm"
            style="margin: 5px; margin-right: 0px"
            v-on:click="closeBedeliaChat()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <Spinner spinkey="bedelia"></Spinner>
  </div>
</template>

<script>
import ivbus from "../utils/ivbus.js";
import {
  getParameterByName,
  ivSpinnerMixin,
} from "../mixins/base-components-pack.js";
import moment from "moment-timezone";

import Spinner from "./Spinner.vue";

export default {
  props: ["context", "renderableChannel", "identity", "friendlyName"],

  components: {
    Spinner,
  },

  mixins: [ivSpinnerMixin],

  data() {
    return {
      candidateAutoReplyMessage: "",
      candidateAutoReplyEnabled: false,
      sendText: "",

      messageBeingSent: false,
      showAutoReplyEditor: false,
    };
  },

  mounted: function () {
    var that = this;
    ivbus.$on("clearSendText", function () {
      that.sendText = "";
    });
  },

  methods: {
    datetimeFormat: function (date) {
      return moment(date).format("MMM D, YYYY h:mm:ss A");
    },

    toggleAutoReply: function () {
      this.candidateAutoReplyEnabled = this.renderableChannel.autoReplyEnabled;
      this.candidateAutoReplyMessage = this.renderableChannel.autoReplyMessage;

      if (this.renderableChannel.autoReplyEnabled) {
        this.renderableChannel.autoReplyEnabled = false;
        this.saveAutoReply();
        return;
      }
      this.renderableChannel.autoReplyEnabled = true;
      this.showAutoReplyEditor = true;
    },

    saveAutoReply: function () {
      this.$emit("saveAutoReply", {
        channel_sid: this.renderableChannel.channel_sid,
        enabled: !this.candidateAutoReplyEnabled,
        message: this.candidateAutoReplyMessage || "",
      });
      this.showAutoReplyEditor = false;
    },

    cancelAutoReplyEditor: function () {
      this.renderableChannel.autoReplyEnabled = this.candidateAutoReplyEnabled;
      this.showAutoReplyEditor = false;
    },

    send: function (event) {
      // if shift is being held down, add newline to sendText and break
      if (event && event.shiftKey) {
        this.sendText += "\n";
        return;
      }

      if (this.messageBeingSent) {
        return;
      }
      if (this.sendText) {
        this.$emit("sendMessage", this.sendText);
      }
    },

    closeBedeliaChat: function () {
      this.$emit("closeBedeliaChat");
    },
  },
};
</script>

<style lang="scss">
</style>